<template>
  <div class="container">
    <div class="hh_center_wrap">
      <el-form class="form" :inline="true">
        <el-row :gutter="18">
          <el-col :span="6">
            <el-form-item label="课程类型">
              <div class="yh_tabs">
                <template v-for="(item, index) in model">
                  <span
                    :key="item.id"
                    :class="active == index ? 'blueColor' : ''"
                    @click="handlerModel(item, index)"
                    >{{ item.name }}</span
                  >
                </template>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程名称">
              <el-input
                v-model="formData.keyword"
                placeholder="请输入关键字"
                maxlength="50"
                clearable
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="课程状态">
              <el-select v-model="formData.state" placeholder="请选择" clearable>
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="dialog_submit">
            <el-button class="btn btn_blue" @click="search()"> 搜索 </el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="table table_warp">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click="handleSelectionChange"
        >
          <el-table-column width="50">
            <template slot-scope="scope">
              <el-radio
                v-model="radio"
                :label="scope.row"
                @change="getTempalteRow(scope.$index, scope.row)"
                >&nbsp;</el-radio
              >
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="课程名称"
            prop="courseTitle"
            width="220"
          />
          <el-table-column
            show-overflow-tooltip
            label="课程状态"
            prop="state"
          />
          <el-table-column show-overflow-tooltip label="创建人" prop="labels" />
          <el-table-column
            show-overflow-tooltip
            label="创建时间"
            prop="createTime"
          />
        </el-table>
        <pagination
          v-if="totalCount > 0"
          :pageSize="formData.pageSize"
          :total="totalCount"
          @pageChange="pageVal"
          ref="refPage"
        ></pagination>
        <div class="yh_step">
          <el-button :disabled="isDisabled" class="btn btn_blue" @click="step"
            >确认</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from "@/utils";
import pagination from "@/components/backStageComponent/pagination";
import API from "@/apis/backStageEndAPI/myCourseAPI";
export default {
  name: "carouselList",
  components: {
    pagination,
  },
  data() {
    return {
      active: '0',
      formData: {
        courseType: 1,
        keyword: "",
        labels: "",
        state: '',
        subjectId: "",
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      radio: false,
      isDisabled: true,
      totalCount: 0,
      itemRow: {},
      stateList: [
        { id: 0, label: "未发布" },
        { id: 1, label: "已发布" },
        { id: 2, label: "待审核" },
        { id: 3, label: "已驳回" },
        { id: 9, label: "已下架" },
      ],
      model: [
        { id: 1,name: '直播'},
        { id: 2,name: '点播'},
      ],
    };
  },
  mounted() {
    this.getCourseList();
  },
  methods: {
    async getCourseList() {
      let data = await API.getBannerCourseList(
        CommonUtils.parseToParams(this.formData)
      );
      if (data && !data.status) {
        this.tableData = data.dataList;
        this.totalCount = data.rowCount;
        data.dataList.forEach((item) => {
          switch (item.state) {
            case 0:
              item.state = "未发布";
              break;
            case 1:
              item.state = "已发布";
              break;
            case 2:
              item.state = "待审核";
              break;
            case 3:
              item.state = "已驳回";
              break;
            case 9:
              item.state = "已下架";
              break;
          }
        });
      }
    },
    getTempalteRow(ind, row) {
      if (row) {
        this.itemRow = row;
        this.isDisabled = false;
      }
    },
    search() {
      this.formData.page = 1;
      this.$refs.refPage.page = 1
      this.getCourseList();
    },
    handlerModel (item,ind) {
      this.active = ind
      this.formData.courseType = item.id
      this.getCourseList();
    },
    step() {
      if (this.itemRow) {
        this.$emit("showPullOne", this.itemRow);
      }
    },
    handleSelectionChange(val) {},
    pageVal(val) {
      this.formData.page = val;
      this.getCourseList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
.hh_top_wrap {
  margin: 0 auto;
  width: 60%;
}
.hh_center_wrap {
  padding: 15px 0 25px 15px;
  width: 100%;
  background: #f4f9fd;
  h1 {
    padding: 15px 10px;
    text-align: left;
    font-size: 24px;
  }
}
.form {
  padding-top: 10px;
}
.table_warp {
  padding-top: 10px;
}
::v-deep .el-form-item__content {
  padding-left: -20px;
  .el-table__empty-block {
    width: 100% !important;
  }
}
.hh_video {
  width: 500px;
  height: 400px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.yh_step {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.yh_tabs {
  width: 160px;
  height: 40px;
  display: flex;
  background: #e6edf5;
  border-radius: 24px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 24px;
  cursor: pointer;
  span {
    display: inline-block;
    width: 150px;
    height: 40px;
  }
  .blueColor {
    display: inline-block;
    width: 150px;
    height: 40px;
    background: #3f8cff;
    border-radius: 24px;
    color: #fff;
  }
}
</style>