<template>
  <div class="container">
    <div class="hh_center_wrap">
      <el-form :model="formData"  label-width="68px">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="资讯分类">
              <el-select v-model="formData.newsType" placeholder="请选择">
                <el-option
                  v-for="item in subjectList"
                  :key="item.id"
                  :label="item.newsType"
                  :value="item.newsType"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资讯名称">
              <el-input
                v-model="formData.newsTitle"
                placeholder="请输入关键字"
                maxlength="50"
                clearable
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资讯状态">
              <el-select v-model="formData.state" placeholder="请选择">
                <el-option
                  v-for="item in stateList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="dialog_submit">
            <el-button class="btn btn_blue" @click="search()"
            >
              搜索
            </el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="table table_warp">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @row-click="handleSelectionChange"
        >
          <el-table-column width="50">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row"
                @change="getTempalteRow(scope.$index,scope.row)"
              >&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="资讯名称" prop="newsTitle" width="220" />
          <el-table-column show-overflow-tooltip label="资讯状态" prop="state" />
          <el-table-column show-overflow-tooltip label="创建人" prop="newsAuthor"  />
          <el-table-column show-overflow-tooltip label="创建时间" prop="updateTime" />
        </el-table>
        <pagination 
          v-if="totalCount > 0" 
          :pageSize="formData.pageSize" 
          :total="totalCount" 
          @pageChange="pageVal"
          ref="refPage"
        ></pagination>
        <div class="yh_step">
          <el-button :disabled="isDisabled" class="btn btn_blue" @click="step">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonUtils from '@/utils'
import pagination from '@/components/backStageComponent/pagination';
import carouselAPI from '@/apis/backStageEndAPI/carouselManagementAPI'
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
export default {
  name: 'consultingList',
  components: {
    pagination
  },
  data() {
    return {
      formData: {
        newsType: '',
        state: '',
        newsTitle: '',
        page: 1,
        pageSize: 10,
      },
      tableData: [],
      radio: false,
      isDisabled: true,
      totalCount: 0,
      itemRow: {},
      subjectList: [],
      stateList:[
        {id: 0, label: '未发布'},
        {id: 1, label: '已发布'},
        {id: 2, label: '审核中'},
        {id: 3, label: '已驳回'},
        {id: 9, label: '已下架'}
      ]
    }
  },
  mounted() {
    this.getCourseList()
    this.getCourseLabel()
  },
  methods: {
    async getCourseList() {
      let data = await carouselAPI.newList(CommonUtils.parseToParams(this.formData))
      if(data && !data.status) {
        this.tableData = data.dataList
        this.totalCount = data.rowCount
        data.dataList.forEach( item => {
          switch(item.state) {
            case 0:
              item.state = '未发布'
              break;
            case 1:
              item.state = '已发布'
              break;
            case 2:
              item.state = '审核中'
              break;
            case 3:
              item.state = '已驳回'
              break;
            case 9:
              item.state = '已下架'
              break;
          }
        })
      }
    },
    getCourseLabel() {
      //资讯分类
      dictionaryAPI.getDictionaryData().then((res) => {//"consult_code"
        if(res.length) {
          this.subjectList = res;
        }
      });
    },
    getTempalteRow(ind,row) {
      if(row) {
        this.itemRow = row
        this.isDisabled = false
      }
    },
    search() {
      this.formData.page = 1
      this.$refs.refPage.page = 1
      this.getCourseList()
    },
    step() {
      if(this.itemRow) {
        this.$emit('newData',this.itemRow)
      }
    },
    handleSelectionChange(val) {},
    pageVal(val) {
      this.formData.page = val;
      this.getCourseList()
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
}
.hh_top_wrap {
  margin: 0 auto;
  width: 60%;
}
.hh_center_wrap {
  padding: 15px 0 25px 15px;
  width: 100%;
  background: #f4f9fd;
  h1 {
    padding: 15px 10px;
    text-align: left;
    font-size: 24px;
  }
}
.form {
  padding-top: 10px;
}
.table_warp {
  padding-top: 10px;
}
::v-deep .el-form-item__content {
  padding-left: 0;
}
.hh_video {
  width: 500px;
  height: 400px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
::v-deep {
  .el-table__empty-block {
    width: 100% !important;
  }
}
.yh_step {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}
</style>