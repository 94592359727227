import axios from '@/plugins/http.js';
class carouselAPI {
  list(params) {//管理端——获取轮播图列表
    return axios.get(`/banner-list?${params}`)
  }
  listView(params) {//管理端——预览列表
    return axios.get(`/banner/preview-list?${params}`)
  }
  addView(params) {//管理端——预览提交
    return axios.post(`/banner/preview`,params)
  }
  addBanner(params) {//管理端——添加
    return axios.post(`/banner`,params)
  }
  editBanner(params) {//管理端——编辑
    return axios.put(`/banner`,params)
  }
  detail(params) {//管理端——详情
    return axios.get(`/banner/${params}`)
  }
  delBanner(params) {//管理端——删除
    return axios.delete(`/banner/${params}`)
  }
  newList(params) {//资讯--列表
    // return axios.get(`/news-list?${params}`)
    return axios.get(`/banner/news-list?${params}`)//轮播图关联所有资讯列表
  }
}
export default new carouselAPI();