<template>
  <div class="wxq_container">
    <el-form
      class="form"
      :model="formData"
      :rules="rules"
      ref="form"
      :inline="true"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item class="wxq_upload" label="轮播图" prop="bannerImg">
            <up-loads
              :callbackList="coverUrl"
              :upStyle="'btnList'"
              ref="isShow"
              @up-success="upLoadImgUrls"
            ></up-loads>
            <ul class="wxq_explain">
              <li><p>建议图片大小2M以内</p></li>
              <li><p>建议图片分辨率865*486像素</p></li>
              <li><p>支持PNG、JPG、JPEG格式</p></li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="标题" prop="bannerTitle">
            <el-input
              v-model.trim="formData.bannerTitle"
              placeholder="请输入轮播图标题"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="跳转至" prop="bannerType">
            <div class="yh_border">
              <span
                :class=" formData.bannerType == index ? 'activeBtn' : 'btn' "
                v-for="(item, index) in typeList"
                @click="changeBannerType(item,index)"
                v-cloak
                >{{ item.name }}</span>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.bannerType != 3">
          <el-form-item label="跳转url" prop="jumpUrl">
            <el-input
              v-model.trim="formData.jumpUrl"
              :placeholder="jumpUrl"
              maxlength="100"
              show-word-limit
              :disabled="formData.bannerType == 3"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="展示时间" prop="dateVal">
            <el-date-picker
              v-model="formData.dateVal"
              type="daterange"
              placement="bottom-start"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="dialog_submit">
          <el-button class="btn btn_blue" @click="getSave('form')"
            >保存</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      class="dialog_wrapper"
      title="课程列表"
      v-if="isPullOne"
      :visible.sync="isPullOne"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      width="1000px"
      @close="colseCarousel"
    >
      <carousel-list @showPullOne="showPullOne" />
    </el-dialog>
    <el-dialog
      class="dialog_wrapper"
      title="资讯列表"
      v-if="isConsulting"
      :visible.sync="isConsulting"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="1000px"
      @close="colseConsulting"
    >
      <consulting-list @newData="newData"/>
    </el-dialog>
  </div>
</template>

<script>
import upLoads from "@/components/backStageComponent/upLoads";
import carouselList from './carouselList'
import consultingList from './consultingList'
import carouselAPI from '@/apis/backStageEndAPI/carouselManagementAPI'
import CommonUtils from "@/utils";
import { getTime } from '@/assets/js/format'
export default {
  name: 'creatCarousel',
  props: {
    id: {
      type: [Number,String],
    }
  },
  components: {
    upLoads,
    carouselList,
    consultingList
  },
  data() {
    return {
      coverUrl: [],
      isPullOne: false,
      isConsulting: false,
      jumpUrl: '',
      formData: {
        bannerTitle: '',
        bannerImg: '',
        bannerType: '-1',
        jumpUrl: '',
        startTime: '',
        endTime: '',
        dateVal: [],
      },
      rules: {
        bannerTitle: [
          { required: true, message: "请输入课程标题", trigger: "blur" },
          { min: 6, max: 100, message: "请输入6-100位课程标题" },
        ],
        bannerImg: [
          { required: true, message: "请选择背景图片", trigger: "change" },
        ],
        dateVal: [
          { type: 'array', required: true, message: "请选择展示时间", trigger: "change" },
        ],
        bannerType: [
          { required: true, message: "请选择跳转至", trigger: "change" },
        ],
        jumpUrl: [
          { required: true, message: "请选择跳转url", trigger: "blur" }
        ]
      },
      startTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 8.64e7;
        },
      },
      itemRow: {},
      typeList: [
        {id: 0 , name: '课程'},
        {id: 1 , name: '资讯'},
        {id: 2 , name: '链接'},
        {id: 3 , name: '无链接'},
      ],
      path: window.location.origin
    }
  },
  mounted() {
    if(this.id) {
      this.getDetail(this.id)
    }
  },
  methods: {
    getTime,
    async getDetail(id) {
      let data = await carouselAPI.detail(id)
        this.formData.bannerImg = data.bannerImg
        this.coverUrl = data.bannerImg.split(",")
        this.$refs.isShow.isShow = this.coverUrl.length === 0;
        this.formData.bannerTitle = data.bannerTitle
        this.formData.bannerType = data.bannerType
        this.formData.jumpUrl = data.jumpUrl
        this.formData.dateVal = [this.getTime(data.startTime),this.getTime(data.endTime)]
    },
    upLoadImgUrls(val) {
      //上传背景图
      this.coverUrl = val;
      this.formData.bannerImg = val.join(",");
      this.$refs.isShow.isShow = this.coverUrl.length === 0;
    },
    changeBannerType(item,index) {
      this.formData.bannerType = index
      if(item && item.id == 0) {
        this.isPullOne = true
        if(!this.id) {
          this.formData.jumpUrl = ''
        }
      }else if(item && item.id == 1) {
        this.isConsulting = true
        if(!this.id) {
          this.formData.jumpUrl = ''
        }
      }else {
        this.formData.jumpUrl = ''
      }
    },
    async getSave(formName) {
      this.$refs[formName].validate(async valid => {
        if(valid) {
          if(this.formData.bannerType < 0 ) {
            this.$message.error('请选择跳转至！')
            return false
          }
          let data = null
          this.formData.startTime = this.formData.dateVal[0] ? this.formData.dateVal[0] : ''
          this.formData.endTime = this.formData.dateVal[1] ? this.formData.dateVal[1] : ''
          delete this.formData.dateVal
          if(this.id) {
            this.formData.id = this.id
            data = await carouselAPI.editBanner(this.formData)
          }else {
            data = await carouselAPI.addBanner(this.formData)
          }
          if(data && !data.status) {
            this.$emit('closeModal',false)
          }
        }
      })
    },
    changeRadio() {
      this.formData.jumpUrl = ''
    },
    colseCarousel() {
      if(this.id) {
        this.getDetail(this.id)
      }else {
        this.formData.bannerType = -1
      }
    },
    colseConsulting() {
      if(this.id) {
        this.getDetail(this.id)
      }else {
        this.formData.bannerType = -1
      }
    },
    showPullOne(bool) {
      this.itemRow = bool
      this.isPullOne = false
      if(bool.courseType == 1) {
        this.formData.jumpUrl = `${this.path}/#/liveDetail?id=${bool.courseId}&courseType=${bool.courseType}&courseLimits=${bool.courseLimits}&state=${bool.state}`
      }else {
        this.formData.jumpUrl = `${this.path}/#/dibbleDetail?id=${bool.courseId}&courseType=${bool.courseType}&courseLimits=${bool.courseLimits}&state=${bool.state}`
      }
    },
    newData(newData) {
      this.isConsulting = false
      if(newData) {
        this.formData.jumpUrl = `${this.path}/#/consultDetail?id=${newData.id}&type=consultDetail&state=${newData.state}`
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.wxq_container{
  ::v-deep .el-form-item {
    width: 100%;
    .el-form-item__label {
      color: #7d8592;
      font-weight: 400;
      font-size: 14px;
    }
    .el-form-item__content {
      display: block;
      .el-cascader {
        width: 100%;
      }
      .el-input__inner {
        border-radius: 14px;
      }
    }
  }
  ::v-deep .wxq_upload {
    .el-form-item__content {
      display: flex;
      .wxq_explain {
        margin-left: 25px;
        padding-top: 14px;
        li {
          position: relative;
          line-height: 28px;
          p {
            margin-left: 12px;
          }
          p:before {
            position: absolute;
            top: 10px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--colorBlue);
            content: "";
          }
        }
      }
    }
    .upLoad_list_big {
      width: 186px;
      height: 104px;
    }
  }
}
.dialog_submit {
  text-align: right;
}
::v-deep {
  .el-image__inner {
    height: 100% !important;
    width: 100% !important;
  }
}
.yh_btn {
  margin-top: 10px;
}
.yh_border {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;
  .btn {
    display: inline-block;
    height: 32px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px 0 0 4px;
    box-shadow: none!important;
    border: 1px solid #dcdfe6;
    color: #606266;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    border-radius: 5px;
  }
  .activeBtn {
    display: inline-block;
    height: 32px;
    color: #fff;
    background-color: #3F8CFF;
    border-radius: 4px 0 0 4px;
    border-color: #3F8CFF;
    box-shadow: -1px 0 0 0 #3F8CFF;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    border-radius: 5px;
    padding: 0 24px;
  }
}
[v-cloak] {
  display: none;
}
</style>
