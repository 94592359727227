import { render, staticRenderFns } from "./carouselList.vue?vue&type=template&id=5c2c6655&scoped=true"
import script from "./carouselList.vue?vue&type=script&lang=js"
export * from "./carouselList.vue?vue&type=script&lang=js"
import style0 from "./carouselList.vue?vue&type=style&index=0&id=5c2c6655&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2c6655",
  null
  
)

export default component.exports