<template>
<!-- 轮播图管理 -->
  <div class="container">
    <header-bar></header-bar>
    <div class="yh_wrapper">
      <div class="yh_tabs">
        <template v-for="(item,index) in model">
          <span :key="item.id" :class="active == index ? 'blueColor' : ''" @click="handlerModel(item,index)">{{item.name}}</span>
        </template>
      </div>
      <div class="yh_submit" @click="submit">
        <span>提交</span>
      </div>
    </div>
    <div class="yh_carousel">
      <template v-for="(item,index) in carouselImg">
        <div class="yh_carousel_img"  :key="index" @click.stop="selectBanner(item,index)">
          <label class="item-status-label">{{ index + 1 }}</label>
          <img 
            class="img" 
            :src="require('@/assets/img/backStageImg/del-icon.png')" 
            alt=""
            v-if="item.bannerTitle"
            @click.stop="delBanner(item,index)">
            <img 
            class="img" 
            :src="require('@/assets/img/backStageImg/del-icon.png')" 
            alt=""
            v-if="item.flagId"
            @click.stop="delimg(item)">
          <img class="imgBanner" :src="item.bannerImg" alt="" v-if="item.bannerImg" />
          <p class="tips" v-else>请从列表中选择轮播图</p>
          <div class="status" :lang="_type(item.bannerState)" v-if="item.bannerState">{{item.bannerState}}</div>
        </div>
      </template>
    </div>
    <div class="yh_filter">
      <el-form :model="form" :inline="true" class="form">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="form.bannerState" clearable placeholder="请选择状态">
                <el-option
                  v-for="item in statusList"
                  :key="item.roleId"
                  :label="item.roleName"
                  :value="item.roleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="关键字">
              <el-input v-model="form.bannerTitle" prefix-icon="el-icon-search" clearable placeholder="请输入关键字" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item>
              <el-button icon="el-icon-search" class="btn btn_blue" @click="find">搜索</el-button>
              <el-button icon="el-icon-plus" class="btn btn_orange" @click="creat">新建轮播图</el-button>
            </el-form-item>  
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table">
      <el-table 
        ref="multipleTable" 
        :data="tableData"
        tooltip-effect="dark"
      >
        <el-table-column  prop="select" width="98"> 
          <template slot-scope="scope">
              <el-radio
                style="margin-left: 20px;"
                @click.native.prevent="getTempalteRow(scope.$index,scope.row)"
                :label="scope.$index"
                v-model="templateRadio"
              />
          </template>
        </el-table-column>
        <el-table-column label="轮播图">
          <template slot-scope="scope">
            <el-image title="预览" :src="scope.row.bannerImg" :preview-src-list="scope.row.srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="bannerTitle"></el-table-column>
        <el-table-column label="跳转目标" prop="bannerType"></el-table-column>
        <el-table-column label="展示时间" width="320">
          <template slot-scope="scope">
            {{ getDate(scope.row.startTime) }} - {{  getDate(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="bannerState">
          <template slot-scope="scope">
            <span v-if="scope.row.bannerState == 1" class="notstart">未开始</span>
            <span v-if="scope.row.bannerState == 2" class="exhibit">展示中</span>
            <span v-if="scope.row.bannerState == 3" class="end">已结束</span>
          </template>
        </el-table-column>
        <el-table-column label="展示范围" prop="org" width="180">
          <template slot-scope="scope">
            <div class="org_range">
              <span :class="scope.row.showWeb ? 'range' : 'exhibition'">WEB</span>
              <span :class="scope.row.showApp ? 'range' : 'exhibition'">APP</span>
              <span :class="scope.row.showH5 ? 'range' : 'exhibition'">H5</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="operate color_blue" @click="edit(scope.row)">编辑</span>
            <span class="operate color_blue" @click="del(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      v-if="total >= 1"
      :pageSize="form.pageSize"
      :total="total"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
      ref="child"
    />
    <el-dialog
      class="dialog_wrapper"
      :title="createTitle"
      v-if="isCreatCarousel"
      :visible.sync="isCreatCarousel"
      :modal-append-to-body="false"
      width="650px"
    >
      <creat-carousel
        :id="currentId"
        @closeModal="closeUserEvent"
      />
    </el-dialog>
  </div>
</template>

<script>
import headerBar from '@comps/backStageComponent/layout/headerBar/headerBar'
import Pagination from '@comps/backStageComponent/pagination'
import creatCarousel from './components/creatCarousel'
import carouselAPI from '@/apis/backStageEndAPI/carouselManagementAPI'
import CommonUtils from '@utils/index.js'
import { getTime ,getDate} from '@/assets/js/format'
export default {
  name: 'carouselManagement',
  components: {
    headerBar,
    Pagination,
    creatCarousel
  },
  data() {
    return {
      active: '0',
      form: {
        bannerState: '0',
        bannerTitle: '',
        page: 1,
        pageSize: 10
      },
      model: [
        { id: 1,name: 'WEB'},
        { id: 2,name: 'APP'},
        { id: 3,name: 'H5'},
      ],
      carouselImg: [],
      statusList: [
        {roleName: '全部',roleId: '0'},
        {roleName: '未开始',roleId: '1'},
        {roleName: '展示中',roleId: '2'},
        {roleName: '已结束',roleId: '3'},
      ],
      tableData: [],
      templateRadio: '',//true
      isCreatCarousel: false,
      total: 0,
      createTitle: '',
      itemRow: {},
      bannerParams: {
        bannerViewParamList: [],
        showArea: 1,
        updaterId: 0
      },
      appBannerParams: {
        bannerViewParamList: [],
        showArea: 2,
        updaterId: 0
      },
      h5BannerParams: {
        bannerViewParamList: [],
        showArea: 3,
        updaterId: 0
      },
      currentId: '',
      flag: '',//标识
      appflag: '',
      h5flag: '',
    }
  },
  mounted() {
    this.getCarouselList()
    this.getListView()
  },
  watch: {
    'bannerParams.bannerViewParamList': {
      handler(val) {
        this.flag = !val.length ? '' : true
      },
      deep: true
    },
    'appBannerParams.bannerViewParamList': {
      handler(val) {
        this.appflag = !val.length ? '' : true
      },
      deep: true
    },
    'h5BannerParams.bannerViewParamList': {
      handler(val) {
       this.h5flag = !val.length ? '' : true
      },
      deep: true
    }
  },
  methods: {
    getTime,
    getDate,
    async getCarouselList() {
      let data = await carouselAPI.list(CommonUtils.parseToParams(this.form))
      if(data && !data.status) {
        this.tableData = data.dataList
        this.total = data.rowCount
        data.dataList && data.dataList.forEach( el => {
          el.srcList = [];
          el.srcList.push(el.bannerImg)
          switch( el.bannerType) {
            case 0:
              el.bannerType = '课程详情'
              break;
            case 1:
              el.bannerType = '咨询详情'
              break;
            case 2:
              el.bannerType = '外部链接'
              break;
            case 3:
              el.bannerType = '无跳转'
              break;  
          }
        })
      }
    },
    async getListView() {
      let params = {
        showArea: this.bannerParams.showArea,
      }
      let data = await carouselAPI.listView(CommonUtils.parseToParams(params))
        this.carouselImg = data
        data.forEach( (el ,index) => {
          switch(el.bannerState) {
            case 0:
              el.bannerState = '';
              break;
            case 1: 
              el.bannerState = '未开始';
              break;
            case 2:
              el.bannerState = '展示中';
              break;
            case 3:
              el.bannerState = '已结束';
              break;
          }
        }) 
        if(this.active == 0) {
          if(data.length) {
            let params = {}
            data.map((item ,index) => {
              if(item.id) {
                params = {
                  id: item.id,
                  sortNum: index + 1
                }
                this.bannerParams.bannerViewParamList.push(params)
              }
            })
            let map = new Map();
            for (let item of this.bannerParams.bannerViewParamList) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                };
            };
            this.bannerParams.bannerViewParamList = [...map.values()];
            localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
          }else {
            this.bannerParams.bannerViewParamList = []
            localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
          }
        }else if(this.active == 1) {
          if(data.length) {
            let params = {}
            data.map((item ,index) => {
              if(item.id) {
                params = {
                  id: item.id,
                  sortNum: index + 1
                }
                this.appBannerParams.bannerViewParamList.push(params)
              }
            })
            let map = new Map();
            for (let item of this.appBannerParams.bannerViewParamList) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                };
            };
            this.appBannerParams.bannerViewParamList = [...map.values()];
            localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
          }else {
            this.appBannerParams.bannerViewParamList = []
            localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
          }
        }else {
          if(data.length) {
            let params = {}
            data.map((item ,index) => {
              if(item.id) {
                params = {
                  id: item.id,
                  sortNum: index + 1
                }
                this.h5BannerParams.bannerViewParamList.push(params)
              }
            })
            let map = new Map();
            for (let item of this.h5BannerParams.bannerViewParamList) {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                };
            };
            this.h5BannerParams.bannerViewParamList = [...map.values()];
            localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
          }else {
            this.h5BannerParams.bannerViewParamList = []
            localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
          }
        }
    },
    async submit() {
        if(this.active == 0) {
            let data = await carouselAPI.addView(this.bannerParams)
            if(data && !data.status) {
              localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
            }
        }
        if(this.active == 1) {
          let data = await carouselAPI.addView(this.appBannerParams)
          if(data &&!data.status) {
            localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
          }
        } 
        if(this.active == 2) {
          let data = await carouselAPI.addView(this.h5BannerParams)
          if(data &&!data.status) {
            localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
          }
        }
        this.getCarouselList()
        this.getListView()
    },
    selectBanner(item,ind) {
      if(!item.bannerImg) {
        if(this.active == 0) {//web
          let el = this.carouselImg[ind]
          let params = {}
          if(Object.keys(this.itemRow).length !=0) {
            el.bannerImg = this.itemRow.bannerImg
            el.flagId = this.itemRow.id
            el.id = this.itemRow.id
            params.id = this.itemRow.id
            params.sortNum = ind + 1
            this.bannerParams.bannerViewParamList.push(params)
          }
          if(localStorage.getItem('bannerData')) {
            let arr = JSON.parse(localStorage.getItem('bannerData'))
            if(arr.length) {
              arr.forEach(itemChild => {
                if(itemChild.id == params.id) {
                  el.bannerImg = ''
                  el.flagId = ''
                  this.$message.error('不能选择重复轮播图！')
                  return false
                }
              })
            }
            let map = new Map()
            for(let item of this.bannerParams.bannerViewParamList) {
              if(!map.has(item.id)) {
                map.set(item.id,item)
              }
            }
            this.bannerParams.bannerViewParamList = [...map.values()]
            localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
            this.flag = true
          }
        }
        if(this.active == 1) {//app
          let appEl = this.carouselImg[ind]
          let appParams = {}
          if(Object.keys(this.itemRow).length != 0 ) {
            appEl.bannerImg = this.itemRow.bannerImg
            appEl.flagId = this.itemRow.id
            appEl.id = this.itemRow.id
            appParams.id = this.itemRow.id
            appParams.sortNum = ind + 1
            this.appBannerParams.bannerViewParamList.push(appParams)
          }
          if(localStorage.getItem('appBannerData')) {
            let appArr = JSON.parse(localStorage.getItem('appBannerData'))
            if(appArr.length) {
              appArr.forEach(appitemChild => {
                if(appitemChild.id == appParams.id) {
                  appEl.bannerImg = ''
                  appEl.flagId = ''
                  this.$message.error('不能选择重复轮播图！')
                  return false
                }
              })
            }
            let appMap = new Map()
            for(let appItem of this.appBannerParams.bannerViewParamList) {
              if(!appMap.has(appItem.id)) {
                appMap.set(appItem.id,appItem)
              }
            }
            this.appBannerParams.bannerViewParamList = [...appMap.values()]
            localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
            this.appflag = true
          }
        }
        if(this.active == 2) {//h5
          let h5El = this.carouselImg[ind]
          let h5Params = {}
          if(Object.keys(this.itemRow).length != 0 ) {
            h5El.bannerImg = this.itemRow.bannerImg
            h5El.flagId = this.itemRow.id
            h5El.id = this.itemRow.id
            h5Params.id = this.itemRow.id
            h5Params.sortNum = ind + 1
            this.h5BannerParams.bannerViewParamList.push(h5Params)
          }
          if(localStorage.getItem('h5BannerData')) {
            let h5Arr = JSON.parse(localStorage.getItem('h5BannerData'))
            if(h5Arr.length) {
              h5Arr.forEach(h5itemChild => {
                if(h5itemChild.id == h5Params.id) {
                  h5El.bannerImg = ''
                  h5El.flagId = ''
                  this.$message.error('不能选择重复轮播图！')
                  return false
                }
              })
            }
            let h5Map = new Map()
            for(let h5Item of this.h5BannerParams.bannerViewParamList) {
              if(!h5Map.has(h5Item.id)) {
                h5Map.set(h5Item.id,h5Item)
              }
            }
            this.h5BannerParams.bannerViewParamList = [...h5Map.values()]
            localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
            this.h5flag = true
          }
        }
      }
    },
    delBanner(item,ind) {
      if(this.active == 0) {
        if(this.bannerParams.bannerViewParamList.length) {
          this.bannerParams.bannerViewParamList.forEach((el,inds) => {
            if(el.id == this.carouselImg[ind].id) {
              this.bannerParams.bannerViewParamList.splice(inds,1)
              localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
              this.carouselImg[ind].bannerImg = ''
              this.carouselImg[ind].bannerState = ''
              this.carouselImg[ind].bannerTitle = ''
            }
          })
          if(this.carouselImg[ind].flagId) {
            this.carouselImg[ind].bannerImg = ''
            // this.carouselImg[ind].bannerState = ''
            this.carouselImg[ind].bannerTitle = ''
          }
        }else {
          this.$message.error('请从列表中选择轮播图！')
          return false
        }
      }
      if(this.active == 1) {
        if(this.appBannerParams.bannerViewParamList.length) {
          this.appBannerParams.bannerViewParamList.forEach((el,inds) => {
            if(el.id == this.carouselImg[ind].id) {
              this.appBannerParams.bannerViewParamList.splice(inds,1)
              localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
              this.carouselImg[ind].bannerImg = ''
              this.carouselImg[ind].bannerState = ''
              this.carouselImg[ind].bannerTitle = ''
            }
          })
          if(this.carouselImg[ind].flagId) {
            this.carouselImg[ind].bannerImg = ''
            this.carouselImg[ind].bannerTitle = ''
          }
        }else {
          this.$message.error('请从列表中选择轮播图！')
          return false
        }
      }
      if(this.active == 2) {
        if(this.h5BannerParams.bannerViewParamList.length) {
          this.h5BannerParams.bannerViewParamList.forEach((el,inds) => {
            if(el.id == this.carouselImg[ind].id) {
              this.h5BannerParams.bannerViewParamList.splice(inds,1)
              localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
              this.carouselImg[ind].bannerImg = ''
              this.carouselImg[ind].bannerState = ''
              this.carouselImg[ind].bannerTitle = ''
            }
          })
          if(this.carouselImg[ind].flagId) {
            this.carouselImg[ind].bannerImg = ''
            this.carouselImg[ind].bannerTitle = ''
          }
        }else {
          this.$message.error('请从列表中选择轮播图！')
          return false
        }
      }
    },
    delimg(item) {
      item.bannerImg = ''
      item.flagId = ''
      if(this.active == 0) {
        this.bannerParams.bannerViewParamList.forEach((el,inds) => {
          if(el.id == item.id) {
            this.bannerParams.bannerViewParamList.splice(inds,1)
          }
        })
        localStorage.setItem('bannerData',JSON.stringify(this.bannerParams.bannerViewParamList))
      }else if(this.active == 1) {
        this.appBannerParams.bannerViewParamList.forEach((el,inds) => {
          if(el.id == item.id) {
            this.appBannerParams.bannerViewParamList.splice(inds,1)
          }
        })
        localStorage.setItem('appBannerData',JSON.stringify(this.appBannerParams.bannerViewParamList))
      }else {
        this.h5BannerParams.bannerViewParamList.forEach((el,inds) => {
          if(el.id == item.id) {
            this.h5BannerParams.bannerViewParamList.splice(inds,1)
          }
        })
        localStorage.setItem('h5BannerData',JSON.stringify(this.h5BannerParams.bannerViewParamList))
      }
    },
    handlerModel(item,ind) {
      this.active = ind
      this.bannerParams.showArea = ind + 1
      this.bannerParams.bannerViewParamList = []
      this.appBannerParams.bannerViewParamList = []
      this.h5BannerParams.bannerViewParamList = []
      this.getListView()
    },
    find() {
      this.form.page = 1
      this.$refs.child.page = 1;
      this.getCarouselList(); //获取列表
    },
    sizeChange(val) {
      this.form.pageSize = val
      this.getCarouselList()
    },
    pageChange(val) {
      this.form.page = val
      this.templateRadio= ''
      this.itemRow = {}
      this.getCarouselList()
    },
    creat() {
      this.currentId = ''
      this.createTitle = '新增'
      this.isCreatCarousel = true
    },
    getTempalteRow(index,row) {
      if(row) {
        if( this.templateRadio ===  index) {
          this.templateRadio= ''
          this.itemRow = {}
        }else {
          this.templateRadio = index
          this.itemRow = row
        }
      }
    },
    edit(row) {
      this.currentId = row.id
      this.createTitle = '编辑'
      this.isCreatCarousel = true
    },
    del(row) {
      this.$confirm('是否要删除！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
      })
        .then(() => {
          carouselAPI.delBanner(row.id).then(res => {
            if(res && !res.status) {
              this.form.page = 1
              this.$refs.child.page = 1;
              this.getCarouselList()
              this.getListView()
            }
          })
        })
        .catch(() => {})
    },
    _type(type) {
      if(type == '已结束') {
        return 'end'
      }else if(type == '未开始') {
        return 'notStarted'
      }else if(type == '展示中') {
        return 'display'
      }
    },
    closeUserEvent(closeUserEvent) {
      this.isCreatCarousel = closeUserEvent
      this.getCarouselList()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/common.scss';
.yh_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .yh_tabs {
    width: 450px;
    height: 40px;
    display: flex;
    background: #E6EDF5;
    border-radius: 24px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 24px;
    cursor: pointer;
    span {
      display: inline-block;
      width: 150px;
      height: 40px;
    }
    .blueColor {
      display: inline-block;
      width: 150px;
      height: 40px;
      background: #3F8CFF;
      border-radius: 24px;
      color: #fff;
    }
  }
  .yh_submit {
    width: 114px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #3F8CFF;
    box-shadow: 0px 6px 12px 0px rgba(63,140,255,0.26);
    border-radius: 14px;
    color: #fff;
    cursor: pointer;
    z-index: 9;
  }
}
.yh_carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin-bottom: 29px;
  .yh_carousel_img {
    position: relative;
    overflow: hidden;
    z-index: 0;
    width: 15%;
    height: 144px;
    margin: 0 12px;
    background: #fff;
    border-radius: 16px;
    &:hover{
      border: 1px solid #3F8CFF;
    }
    &:hover > .img {
      width: 19px !important;
      height: 19px !important;
      position: absolute;
      top: 5px;
      right: 6px;
      z-index: 5;
    }
    .img {
      position: absolute;
      top: 5px;
      right: 6px;
      width: 0px !important;
      height: 0px !important;
    }
    
    .imgBanner {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      max-width: 80%;
      max-height: 90%;
      margin: auto;
    }
    &:hover > .tips {
      text-align: center;
      line-height: 135px;
      width: 140px;
      height: 24px;
      margin: 0 auto;
      font-size: 14px;
      color: #3F8CFF;
    }
    .tips{
      text-align: center;
      line-height: 130px;
      width: 140px;
      height: 24px;
      margin: 0 auto;
      font-size: 14px;
      color: #3F8CFF;
    }
    .item-status-label {
      position: absolute;
      left: -16px;
      top: -7px;
      width: 42px;
      line-height: 37px;  
      height: 26px;
      color: #fff;
      background: #3F8CFF;
      text-align: center;
      transform: rotate(-45deg);
      z-index: 6;
    }
    .status {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 27px;
      border-radius: 1px;
      text-align: center;
      line-height: 27px;
      color: #fff;
      &:lang(end) {
        background: #CED5E0;
      }
      &:lang(notStarted) {
        background: #FDC748;
      }
      &:lang(display) {
        background: #00D097;
      }

    }
  }
}
.yh_filter {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  .form {
    margin-top: 20px;
  }
}
.range {
  display: inline-block;
  width: 45px;
  height: 25px;
  background: #3F8CFF;
  border-radius: 22px;
  margin: 0 3px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
}
.exhibition{
  display: inline-block;
  width: 45px;
  height: 25px;
  background: #F4F9FD;
  border-radius: 22px;
  margin: 0 3px;
  color: #91929E;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
}
.notstart {
  display: inline-block;
  width: 50px;
  height: 25px;
  background: #FDC748;
  border-radius: 22px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
}
.end {
  display: inline-block;
  width: 50px;
  height: 25px;
  background: #CED5E0;
  border-radius: 22px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
}
.exhibit {
  display: inline-block;
  width: 50px;
  height: 25px;
  background: #00D097;
  border-radius: 22px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #fff;
}
::v-deep {
  .el-image__inner {
    height: 45px;
  }
  .el-radio__inner{
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .el-radio__label {
    display: none;
  }
  .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
    box-shadow: none;
  }
}
</style>